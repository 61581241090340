// src/pages/SuccessPage.tsx
import React from 'react';

const SuccessPage: React.FC = () => {
  return (
    <div>
      <h1>Integration Successful</h1>
      <p>Your QuickBooks account has been successfully connected.</p>
    </div>
  );
};

export default SuccessPage;
